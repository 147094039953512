<template>
  <section>
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-2 d-flex justify-content-end pm-0">
          <button
            class="btn btn-info btn-sm rounded-0 space-x-2"
            @click="refresh"
          >
            <span>تحديث</span>
            <i class="fa fa-sync"></i>
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="container" v-if="!!auctionValidation && !auctionValidation.state">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger py-3">
            <h4 class="text-center">لا يمكن تعديل حالة المزاد</h4>
            <ol>
              <li v-for="error in auctionValidation.errors" :key="error">
                <p>{{ error }}</p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-between flex-wrap">
          <p>
            <span>حالة المزاد:</span>
            <span v-if="!!status" class="link">{{
                status.text || "غير محددة"
              }}</span>
            <span v-else-if="!shouldReload" class="text-secondary"
            >غير محددة</span
            >
          </p>
          <p>
            <label class="mx-1">المزاود الأعلى</label>
            <router-link :to="winnerRedirect">
              <label v-if="!!winner" class="link">{{
                  winner.name || "غير محدد"
                }}</label>
              <label v-else-if="!shouldReload" class="text-secondary"
              >غير محدد</label
              >
            </router-link>
          </p>
          <p>
            <span>السعر الحالي:</span>
            <span class="link">
            <span>{{ currentPrice }}</span
            ><span>دينار</span>
          </span>
          </p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-sm-12">
          <h5 class="text-center">تعديل حالة المزاد</h5>
          <div class="jumbotron" v-if="!dateFinished">
            <h6 class="text-danger text-center">
              لا يمكن التعديل, لم ينتهي المزاد بعد
            </h6>
          </div>
          <div v-else>
            <ul class="y-0">
              <li v-if="isSold">
                <small class="success-bold">تم سحب المزاد من قبل التأمين</small>
              </li>
              <li v-if="transferred">
                <small class="success-bold">تم نقل المركبة بونش المزاد</small>
              </li>
              <li v-else>
                <small class="success-bold">تم نقل المركبة بونش خارجي</small>
              </li>
              <li v-if="hasInvoice">
                <small class="success-bold">تم إنشاء فاتورة</small>
              </li>
              <li v-else-if="hasCheckedInvoice">
                <small class="success-bold">تم إنشاء فاتورة وتدقيقها</small>
              </li>
              <li v-if="isStumbledState">
                <small class="danger-bold" v-if="priceLess"
                >سبب التعثر: السعر الحالي أقل من الحد الأدنى لبيع المزاد</small
                >
                <small class="danger-bold" v-else
                >سبب التعثر: تم التعيين متعثراً بشكل يدوي</small
                >
              </li>
              <li v-if="isWithoutInvoice">
                <small class="danger-bold">هذا المزاد بدون فاتورة</small>
              </li>
              <li v-if="!isSold && !isFloorPaid">
                <small class="danger-bold">لم يتم دفع الأرضيات</small>
              </li>
            </ul>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                <tr class="bg-bb">
                  <th>العملية</th>
                  <th>الحالة</th>
                  <th>تعديل</th>
                </tr>
                </thead>
                <tbody>
                <AuctionStatusItem
                  v-for="item in result"
                  :item-status="item"
                  :status="status"
                  :key="item.id"
                  :invoice="invoice"
                  :result="result"
                  :price-less="priceLess"
                  :transferred="transferred"
                  @change-status="changeStatus"
                />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <section
        v-if="isStumbledState && !(!this.dateFinished || isSold || isPaid || isFloorPaid)">
        <hr />

        <div class="row" id="stumbled">
          <div class="col-sm-12">
            <h5 class="text-center">عمليات تعثر المزاد</h5>
          </div>
          <div class="col-sm-6">
            <base-card header="bg-bb" footer="bg-e0">
              <template #header>إعادة طرح المزاد</template>
              <template #body>
                <form id="restart-form">
                  <FormInput
                    type="number"
                    id="opening_price"
                    text="سعر الإفتتاح"
                  />
                  <p class="pm-0">
                    <small class="danger-bold"
                    >في حال عدم تحديد سعر اللإفتتاح سوف يبدأ المزاد من سعر
                      اللإفتتاح المعين مسبقاً</small
                    >
                  </p>
                  <FormInput
                    type="datetime-local"
                    id="start_date"
                    text="تاريخ البدء"
                    @changed="checkDate"
                  />
                  <FormInput
                    type="datetime-local"
                    id="finish_date"
                    text="تاريخ الإنتهاء"
                    @changed="checkDate"
                  />
                  <button type="submit" hidden id="restart"></button>
                </form>
              </template>
              <template #footer>
                <ModalBtn
                  target="#restart-modal"
                  btn-class="btn btn-block btn-sm btn-soft-danger"
                >إعادة الطرح
                </ModalBtn
                >
              </template>
            </base-card>
          </div>
          <div class="col-sm-6">
            <base-card header="bg-bb" footer="bg-e0">
              <template #header>تعيين المزاد على مشترك</template>
              <template #body>
                <p class="font-weight-bold">انتبه:</p>
                <p>عند تعيين المزاد على مشترك سوف يتم الغاء تعثر المزاد</p>
                <p>يرجى تحديد المستخدم والسعر الذي تريد ارساءه على المشترك</p>
                <p>
                  سوف يتم حذف جميع المزاودات الحالية لهذا المزاد بشكل غير نهائي
                </p>
              </template>
              <template #footer>
                <ModalBtn
                  target="#assign-modal"
                  btn-class="btn btn-block btn-sm btn-soft-danger"
                  @click="setShowUsers(true)"
                >تعيين المزاد على مشترك
                </ModalBtn>
              </template>
            </base-card>
          </div>
        </div>
      </section>

      <section>
        <hr />
        <div class="row">
          <div class="col-sm-12">
            <h5 class="text-center">الفاتورة</h5>
          </div>
          <div class="col-sm-12 d-flex justify-content-end">
            <router-link
              v-if="canCreateInvoice"
              :to="{ name: 'invoices.create', params: { auction: auctionId } }"
              class="btn btn-sm rounded-0 btn-info"
            >
              <span>إنشاء فاتورة</span>
              <span class="fa fa-plus"></span>
            </router-link>
            <button v-else class="btn btn-sm rounded-0 btn-info" disabled>
              <span>إنشاء فاتورة</span>
              <span class="fa fa-plus"></span>
            </button>
            <modal-btn
              v-if="isUncheckedInvoiceState"
              title="إلغاء الفاتورة"
              target="#cancel-invoice"
              btn-class="btn-sm btn-danger ml-1"
            >
              إلغاء الفاتورة
              <span class="fa fa-times"></span>
            </modal-btn>
          </div>
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                <tr class="bg-bb">
                  <th>رقم الفاتورة</th>
                  <th>الحالة</th>
                  <th>تعديل</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!hasInvoice">
                  <td colspan="3" class="text-center font-weight-bold">
                    لا يوجد فاتورة
                  </td>
                </tr>
                <tr v-else>
                  <td>
                    <p class="invoice-name" title="طباعة الفاتورة">
                      {{ invoice.number }}
                    </p>
                  </td>
                  <td>
                    <i class="badge badge-success" v-if="hasCheckedInvoice"
                    >مدققة</i
                    >
                    <i class="badge badge-danger" v-else>غير مدققة</i>
                  </td>
                  <td>
                    <div v-if="hasInvoice">
                      <div class="d-inline" v-if="!hasCheckedInvoice">
                        <modal-btn v-if="superAdmin" target="#check-invoice" btn-class="btn-sm pm-0">
                          <span class="link">تدقيق</span>
                        </modal-btn>
                        <span v-if="superAdmin">/</span>
                        <router-link class="link" :to="{name: 'invoices.edit', params: { invoice: invoice.id }}">
                          تعديل
                        </router-link>
                        <span>/</span>
                      </div>
                      <DownloadInvoice :invoice-id="invoice.id" />
                    </div>
                    <small class="no-edit" v-else>لا يمكن التعديل</small>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <hr />

      <teleport to="#app">
        <confirm-model
          v-if="superAdmin && isUncheckedInvoiceState"
          target-id="cancel-invoice"
          title="إلغاء الفاتورة"
          @confirmed="cancelInvoice"
        >
          <span>هل أنت متأكد من إلغاء الفاتورة ؟</span>
        </confirm-model>
        <confirm-model
          v-if="superAdmin && isStumbledState"
          target-id="restart-modal"
          title="إعادة طرح المزاد"
          @confirmed="restart"
        >
          <span>هل أنت متأكد من إعادة طرح المزاد ؟</span>
        </confirm-model>
        <confirm-model
          v-if="isStumbledState"
          target-id="assign-modal"
          :full="true"
          title="تعيين المزاد على مشترك"
          footer-classes="hide"
          @confirmed="restart"
          @dismissed="setShowUsers(false)"
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-10">
                <AssignAuction
                  v-if="showUsers"
                  :auction-id="auctionId"
                  :auction-result="result"
                  :current-price="currentPrice"
                  @assign="assign"
                  @hide-users="hideUsers"
                />
              </div>
            </div>
          </div>
        </confirm-model>
        <confirm-model
          v-if="superAdmin && isUncheckedInvoiceState"
          target-id="check-invoice"
          title="تدقيق الفاتورة"
          header-bg="bg-info"
          confirm-text="تدقيق"
          @confirmed="checkInvoice"
        >
          <p class="pm-0">هل أنت متأكد من تدقيق الفاتورة ؟</p>
          <small class="font-weight-bold text-danger"
          >ملاحظة: هذه العملية لا يمكن الرجوع عنها</small
          >
        </confirm-model>
      </teleport>
    </div>

    <!-- Delete Auction -->
    <div class="container" v-if="superAdmin">
      <div class="row">
        <div class="col-sm-12">
          <div class="d-flex justify-content-end">
            <modal-btn target="#delete_auction" btn-class="btn-danger">
              حذف المزاد
            </modal-btn>
          </div>
        </div>
      </div>
      <teleport to="#app">
        <confirm-model
          v-if="superAdmin"
          target-id="delete_auction"
          header-bg="bg-danger"
          title="حذف المزاد"
          confirm-btn="btn-danger"
          cancel-btn="btn-info"
          @confirmed="remove"
        >
          <h5>هل أنت متأكد من حذف المزاد ؟</h5>
        </confirm-model>
      </teleport>
    </div>
  </section>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import FormInput from "@/components/Forms/FormInput";
import ConfirmModel from "@/components/UI/ConfirmModel";
import ModalBtn from "@/components/UI/ModalBtn";
import errors from "@/mixins/errors";
import AssignAuction from "@/components/Auctions/AssignAuction";
import Storage from "@/common/Storage";
import DownloadInvoice from "@/components/Invoices/DownloadInvoice";
import AuctionStatusItem from "@/components/Auctions/AuctionStatusItem";
import AuctionStatusMixin from "@/mixins/AuctionStatusMixin";
import auctions from "@/common/auctions";
import UserRole from "@/extra/enums/UserRole";
import types from "@/common/types";

export default {
  components: {
    AuctionStatusItem,
    DownloadInvoice,
    AssignAuction,
    ModalBtn,
    ConfirmModel,
    FormInput,
    BaseCard,
  },
  mixins: [errors, AuctionStatusMixin],
  data() {
    return {
      auction: "",
      result: "",
      winner: "",
      status: "",
      invoice: "",
      showUsers: false,
      selectedStatus: "",
    };
  },
  computed: {
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
    authorizedId() {
      const authorized = this.$store.getters.authorized;
      return !!authorized ? authorized.id : "";
    },
    shouldReload() {
      return this.$store.getters.shouldReload;
    },
    auctionId() {
      return this.$route.params.auction;
    },
    currentPrice() {
      if (!this.auction) {
        return 0;
      }

      return this.auction.current_price > 0
        ? this.auction.current_price
        : this.auction.opening_price;
    },
    priceLess() {
      return (
        parseFloat(this.auction.current_price) <
        parseFloat(this.auction.min_price)
      );
    },
    transferred() {
      return types.checkY(this.auction.transferred);
    },
    isClient() {
      return !!this.winner && this.winner.role === UserRole.Client;
    },
    winnerRedirect() {
      return this.isClient
        ? {name: "users.edit", params: {user: this.winner.id}}
        : "#";
    },
    dateFinished() {
      return !this.status || parseInt(this.status.number) !== 1;
    },
    soldButTransferred() {
      return this.transferred && this.isSold;
    },
    canCreateInvoice() {
      return (
        !this.isWithoutInvoice &&
        !this.hasInvoice &&
        ((!this.isStumbledState && this.isPaid) ||
          (this.isSold && this.transferred))
      );
    },
    auctionValidation() {
      return auctions.valid(this.auction);
    },
  },
  methods: {
    setData(data) {
      if (!!data) {
        this.auction = data.auction;
        this.result = data.result;
        this.winner = data.winner;
        this.status = data.status;
        this.invoice = data.invoice;
      }
    },
    async setStatus() {
      if (!!this.auctionId) {
        const response = await http.send("auction-status", {
          _method: "GET",
          auction: this.auctionId,
        });
        if (http.responseAccepted(response)) this.setData(response.data);
      }
    },
    async restart() {
      const formData = new FormData(document.getElementById("restart-form"));
      if (!formData.get("finish_date"))
        return http.popupMessage("error", "خطأ, لم يتم تحديد تاريخ الإنتهاء");
      formData.set("auction", this.auctionId);
      const response = await http.send("restart-auction", formData);
      if (http.responseAccepted(response)) {
        this.clearStorage();
        this.setData(response.data);
        $_("#restart-form input").val("");
        http.popupMessage("success", "تم إعادة طرح المزاد بنجاح");
        this.hideModal("#restart-modal");
      }
    },
    async assign(e) {
      const formData = new FormData(e.target);
      if (!formData.get("user"))
        return http.popupMessage("error", "خطأ, لم يتم تحديد المستخدم");
      if (!formData.get("price"))
        return http.popupMessage("error", "خطأ, لم يتم تحديد السعر");
      formData.set("auction", this.auctionId);
      const response = await http.send("assign-auction", formData);
      if (http.responseAccepted(response)) {
        http.popupMessage("success", "تم تعيين المزاد على المشترك بنجاح");
        this.clearStorage();
        this.setData(response.data);
        this.hideUsers();
      }
    },
    async changeStatus(status) {
      if (!status || !this.auctionId) {
        console.error("selected status is empty!");
      } else {
        const response = await http.send("auction-status", {
          status,
          auction: this.auctionId,
        });
        if (http.responseAccepted(response)) {
          http.popupMessage("success", messages.updated);
          this.clearStorage();
          this.setData(response.data);
        }
      }
    },
    async cancelInvoice() {
      if (!!this.hasInvoice) {
        const response = await http.send("invoices/cancel", {
          invoice: this.invoice.id,
        });
        if (http.responseAccepted(response)) {
          await this.clearStorage();
          location.reload();
        }
      }
    },
    setShowUsers(showUsers) {
      this.showUsers = showUsers;
    },
    hideUsers() {
      this.setShowUsers(false);
      this.hideModal("#assign-modal");
    },
    clearStorage() {
      Storage.remove("admin-counters");
      Storage.removeStartWith("auction-categories");
    },
    async downloadInvoice() {
      if (!this.invoice)
        return http.popupMessage("error", "لم يتم إنشاء فاتورة");
      await http.send("invoice/download", {
        _method: "GET",
        invoice: this.invoice.id,
      });
    },
    async checkInvoice() {
      if (!!this.invoice) {
        const response = await http.send("invoices/check", {
          invoice: this.invoice.id,
        });
        if (http.responseAccepted(response, "invoice_checked")) {
          http.popupMessage("success", "تم تأكيد الفاتورة");
          this.clearStorage();
          this.hideModal("#check-invoice");
          await this.setStatus();
        }
      }
    },
    async remove() {
      if (!!this.auctionId) {
        const response = await http.send(`auctions/${this.auctionId}`, {
          _method: "DELETE",
        });
        if (http.responseAccepted(response)) {
          this.clearStorage();
          http.popupMessage("success", "تم حذف المزاد بنجاح");
          await this.$router.replace({name: "admin.auctions.deleted"});
        }
      }
    },
    connectToChannel() {
      Echo.leave(`auction-status-${this.auctionId}`);
      Echo.private(`auction-status-${this.auctionId}`).listen(
        "AuctionStatusEvent",
        (e) => {
          if (!!e && !!e.status && parseInt(this.authorizedId) !== e.userId)
            this.setData(e.status);
        }
      );
    },
    async refresh() {
      await this.setStatus();
      this.connectToChannel();
    },
  },
  async created() {
    await this.setStatus();
    this.connectToChannel();
  },
};
</script>

<style scoped>
span {
  padding: 0 0.1rem;
}

.no-edit {
  font-weight: bold;
  color: #dc3545;
  cursor: not-allowed;
}

.invoice-name {
  font-weight: bold;
  padding: 0;
  margin: 0;
}

#stumbled p {
  text-align: right;
}

.col-sm-12,
.col-sm-6 {
  padding: 0.25rem;
}
</style>
